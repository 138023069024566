import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/reset.scss'
import '@/assets/style/global.scss'

import echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import Element from "element-ui";
Vue.use(Element);
import 'element-ui/lib/theme-chalk/index.css';
import "amfe-flexible/index.js";


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')




