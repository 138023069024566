<template>
  <div id="app" ref="app">
    <router-view />
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  data() {
    return {};
  },
  mounted(){
    if (document.body.clientWidth > 450){
      this.pc()
    }

  },
  methods: {
    pc(){
      this.$nextTick(() => {
        const $app = this.$refs.app;
        const standardScale = "100%" / "100%";
        window.addEventListener(
            "resize",
            _.debounce(function () {
              const docHeight = document.body.clientHeight;
              const docWidth = document.body.clientWidth;

              if (docWidth < 1680) {
                const currentScale = docHeight / docWidth;
                let [scale, translate] = [0, 0];
                if (currentScale < standardScale) {
                  scale = docHeight / 1080;
                  const shouleWidth = 1920 * scale;
                  const offsetWidth = docWidth - shouleWidth;
                  translate =
                      offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : "";
                } else {
                  scale = docWidth / 1920;
                  const shouleHeight = 1080 * scale;
                  const offsetHeight = docHeight - shouleHeight;
                  translate =
                      offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : "";
                }
                $app.style.cssText = `
            transform: scale(${scale}) ${translate};
            transform-origin: top left;
            min-width: 1920px;
            min-height: 1080px;
          `;
              } else {
                $app.style.cssText = "";
              }
            }),
            300
        );

        if (document.createEvent) {
          var event = document.createEvent("HTMLEvents");
          event.initEvent("resize", true, true);
          window.dispatchEvent(event);
        } else if (document.createEventObject) {
          window.fireEvent("onresize");
        }
      })
    },
  },

};
</script>
<style>
</style>
