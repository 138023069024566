import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    idCode: sessionStorage.getItem('idCode') || '',
  },
  getters: {
    idCode: (state) => state.idCode,
  },
  mutations: {
    SET_ID_CODE: (state, idCode) => {
      state.idCode = idCode
    },
  },
  actions: {
  },
  modules: {
  }
})
